.container {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.22);
  padding: 0 30px;
}

.leftNavbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerNavbar{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:20px;
}

.rightNavbar {
  /* background-color: orange; */
  display: flex;
  gap: 20px;
  align-items: center;
}

