.Toastify__progress-bar--success {
    background: #8C52FF;
  }

/* Warna teks ikon */
/* .Toastify__toast--success .Toastify__toast-body {
    color: #8C52FF; 
  } */
  
  /* Warna ikon */
/* .Toastify__toast--success .Toastify__icon {
    color: #8C52FF; 
} */