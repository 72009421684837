.container{
    /* background-color: brown; */
    height: 80vh  ;
    max-height: 80vh;
    padding: 20px 30px;
    transition: 3s all ease-out;
}

.listMenuContainer{
    /* background-color: blue; */
    height: 80vh;
    max-height: 80vh;
    /* overflow: auto; */
}

.summeryCardContainer{
    /* background-color: green; */
    height: 80vh;
    max-height: 80vh;
    overflow: hidden;
}
